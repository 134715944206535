import React from "react";
class Wallet extends React.Component {
  render() {
    return(
      <>
        <div>
            This is Wallet page
        </div>
      </>
    );
  }
}
export default Wallet;