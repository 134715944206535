import React from "react";
class Error extends React.Component {
  render() {
    return(
      <>
        <div>
            Sorry, Page not found!
        </div>
      </>
    );
  }
}
export default Error;