import React from "react";
class Setting extends React.Component {
  render() {
    return(
      <>
        <div>
            This is Admin Setting
        </div>
      </>
    );
  }
}
export default Setting;